// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ausfluege-js": () => import("./../src/pages/ausfluege.js" /* webpackChunkName: "component---src-pages-ausfluege-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-feedback-js": () => import("./../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-galerie-js": () => import("./../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-hotel-js": () => import("./../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-2-js": () => import("./../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-restaurants-js": () => import("./../src/pages/restaurants.js" /* webpackChunkName: "component---src-pages-restaurants-js" */)
}

